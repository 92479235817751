import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel } from '@material-ui/core';
import EntityX from 'components/inputs/EntityX';
import FAIcon from 'components/ui/FAIcon';
import Job from 'entities/Job/Job';
import Location from 'entities/Location/Location';
import { Field, Form, Submit, useFieldObserver } from 'frmx';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { createAutomatisation, updateAutomatisation } from 'store/managersSlice';
import DialogTitle from 'components/dialogs/DialogTitle';
import { useEffect, useState } from 'react';

export default function ModalAutomatisation({ open, onClose, manager, kind, automatisation }) {
  const { t } = useTranslation();
  const notify = useNotifications();
  const { dispatch } = useAsyncDispatch();
  const [errors, setErrors] = useState({ jobsError: false, locationsError: false }); // Utilisation d'un état groupé pour les erreurs

  const isCreate = !automatisation?._id;

  // Fonction pour vérifier les erreurs
  const validateFields = (data) => {
    const newErrors = {
      jobsError: !data.allJobs && !data._jobs.length,
      locationsError: !data.allLocations && !data._locations.length
    };
    setErrors(newErrors);

    return !newErrors.jobsError && !newErrors.locationsError; // Retourne vrai si aucune erreur
  };

  useEffect(() => {
    if (open) {
      setErrors({ jobsError: false, locationsError: false });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <Form
        initialValues={{
          isActive: automatisation?.isActive || false,
          allLocations: automatisation?.allLocations || false,
          allJobs: automatisation?.allJobs || false,
          _jobs: automatisation?._jobs || [],
          _locations: automatisation?._locations || [],
          kind
        }}
        onSubmit={(data) => {
          if (!validateFields(data)) return;

          dispatch(
            isCreate ? createAutomatisation : updateAutomatisation,
            {
              ...data,
              allJobs: data.allJobs || (!data.allJobs && !data._jobs.length),
              _jobs:
                data._jobs.length && !data.allJobs
                  ? data?._jobs?.map((j) => ({ _id: j._id, name: j.name }))
                  : manager?._jobs?.map((j) => ({ _id: j._id, name: j.name })),
              allLocations: data.allLocations || (!data.allLocations && !data._locations.length),
              _locations:
                data._locations.length && !data.allLocations
                  ? data._locations.map((l) => ({ _id: l._id, name: l.name }))
                  : manager._locations.map((l) => ({ _id: l._id, name: l.name }))
            },
            { onError: () => notify.error() },
            {
              id: manager._id,
              automatisationId: isCreate ? undefined : automatisation._id
            }
          ).then(() => {
            notify.success(isCreate ? 'createdAutomatisation' : 'editedAutomatisation');
            onClose();
          });
        }}
      >
        <DialogTitle
          title={isCreate ? t('createAutomatisation') : t('editAutomatisation')}
          onClose={onClose}
        />
        <DialogContent>
          <AutomatisationDialogContents
            manager={manager}
            jobsError={errors.jobsError}
            locationsError={errors.locationsError}
          />
        </DialogContent>
        <DialogActions>
          <Submit>
            <Button
              endIcon={
                <FAIcon
                  collection="fas"
                  icon="bolt"
                />
              }
            >
              {t('save2')}
            </Button>
          </Submit>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

const AutomatisationDialogContents = ({ manager, jobsError, locationsError }) => {
  const allLocations = useFieldObserver('allLocations');
  const allJobs = useFieldObserver('allJobs');

  const managerJobIds = manager?._jobs?.map((j) => j._id);
  const managerLocationIds = manager?._locations?.map((l) => l._id);

  const { t } = useTranslation();

  return (
    <>
      <p className="mt-2 text-sm">{t('automatisation-jobs-and-locations-are-according-to-manager-scope')}</p>
      <FormControlLabel
        control={
          <Field
            path="allJobs"
            type="checkbox"
            isErrorProp={jobsError}
          >
            <Checkbox />
          </Field>
        }
        label={t('selectAllJobs')}
        className="mt-4"
      />
      {/* select all jobs or select jobs */}
      <p>{jobsError && <span className="text-red-500">{t('selectAtLeastOneJob')}</span>}</p>
      <Box className="mt-1">
        <EntityX
          disabled={allJobs}
          disabledHelperText={t('allJobsAlreadySelected')}
          path="_jobs"
          entityName="jobs"
          entity={Job}
          placeholder={t('entityJobName')}
          entityProps={{ hiddenFilters: { only: managerJobIds } }}
        />
      </Box>

      <FormControlLabel
        control={
          <Field
            path="allLocations"
            type="checkbox"
            isErrorProp={locationsError}
          >
            <Checkbox />
          </Field>
        }
        label={t('selectAllLocations')}
        className="mt-4"
      />
      <p>{locationsError && <span className="text-red-500">{t('selectAtLeastOneLocation')}</span>}</p>
      <Box className="mt-1">
        <EntityX
          disabled={allLocations}
          disabledHelperText={t('allLocationsAlreadySelected')}
          path="_locations"
          entityName="locations"
          entity={Location}
          placeholder={t('locations')}
          entityProps={{ hiddenFilters: { only: managerLocationIds } }}
        />
      </Box>
    </>
  );
};
