import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/managersSlice';

import ManagerMenus from './ManagerMenus';
import ManagerPreview from './ManagerPreview';
import ManagerSkeleton from './ManagerSkeleton';
import ManagerView from './ManagerView';
import ManagerTabs from './ManagerTabs';
import ManagerFilters, { formatFilter } from './ManagerFilters';
import ManagerFormValues, { ManagerValidation, formatForm } from './ManagerFormValues';
import ManagerTranslations from './ManagerTranslations';
import ManagerExport from './ManagerExport';
import ManagerForm from './ManagerForm';
import ManagerSorts from './ManagerSorts';

import { PhotoCamera } from '@material-ui/icons';

import EntityWrapper from 'entities/EntityWrapper';
import ManagerTable from './ManagerTable';

export default function Manager(props) {
  return (
    <EntityWrapper
      entity="managers"
      entityFieldsSlice="manager"
      skeletonComponent={ManagerSkeleton}
      previewComponent={ManagerPreview}
      viewComponent={ManagerView}
      formComponent={ManagerForm}
      formatForm={formatForm}
      formatFilter={formatFilter}
      getFilters={ManagerFilters}
      getInitialValues={ManagerFormValues}
      getFormValidation={ManagerValidation}
      getTabs={ManagerTabs}
      getTables={ManagerTable}
      getMenus={ManagerMenus}
      getExportColumns={ManagerExport}
      getTranslations={ManagerTranslations}
      getSorts={ManagerSorts}
      getElement={getElement}
      getElements={getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      formAttachment
      formAttachmentIcon={<PhotoCamera />}
      formAttachmentLabel={'Photo de du gestionnaire'}
      {...props}
    />
  );
}
