import { createSlice } from '@reduxjs/toolkit';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import generateAsyncThunk from 'utils/generateAsyncThunk';

export const createElement = generateAsyncThunk({ type: 'POST', endpoint: 'users' });
export const updateElement = generateAsyncThunk({ type: 'PUT', endpoint: 'users/${id}' });
export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'users/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'users/list' });
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'users/${id}' });
export const restoreElement = generateAsyncThunk({ type: 'POST', endpoint: 'users/recycle/${id}' });

export const getAutomatisations = generateAsyncThunk({ type: 'GET', endpoint: 'managers/${id}/automatisations' });
export const createAutomatisation = generateAsyncThunk({ type: 'POST', endpoint: 'managers/${id}/automatisations' });
export const updateAutomatisation = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'managers/${id}/automatisations/${automatisationId}'
});
export const deleteAutomatisation = generateAsyncThunk({
  type: 'DELETE',
  endpoint: 'managers/${id}/automatisations/${automatisationId}'
});

const entityBoilerplate = generateEntityBoilerplate({
  createElement,
  updateElement,
  getElement,
  getElements,
  deleteElement,
  restoreElement
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const managersSlice = createSlice({
  name: 'managers',
  initialState,
  reducers,
  extraReducers: {
    ...extraReducers,
    [getAutomatisations.fulfilled]: (state, action) => {
      state.db[action.meta.id].automatisations = action.payload.data.automatisations;
    },
    [createAutomatisation.fulfilled]: (state, action) => {
      const index = action.meta.id;
      state.db[index].automatisations.push(action.payload.data.automatisation);

      state.db[index].tabs.automatisations = state.db[index].automatisations.length;
    },
    [updateAutomatisation.fulfilled]: (state, action) => {
      const index = action.meta.id;
      const id = action.meta.automatisationId;

      state.db[index].automatisations = state.db[index].automatisations.map((t) => {
        if (t._id === id) {
          return action.payload.data.automatisation;
        }
        return t;
      });
    },
    [deleteAutomatisation.fulfilled]: (state, action) => {
      const index = action.meta.id;
      const id = action.meta.automatisationId;

      state.db[index].automatisations = state.db[index].automatisations.filter((t) => t._id !== id);

      state.db[index].tabs.automatisations = state.db[index].automatisations.length;
    }
  }
});

export const { flushElements, flushElement } = managersSlice.actions;
export default managersSlice.reducer;
