import { Divider, Box, Tooltip, Badge, Button, IconButton, Grid, Typography } from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import FAIcon from 'components/ui/FAIcon';

import { uploadDocument, uploadDocumentPublic } from 'store/authSlice';

import { isObject, isArray, cloneDeep, pick, isString } from 'lodash-es';

import { useHistory } from 'react-router-dom';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { useEntity } from 'contexts/entities/entityContext';
import { Form, useFieldObserver, useForm, useSubmitListener } from 'frmx';

import useStyles from 'components/dialogs/Dialog.styles';

import { useMemo, useRef, useState, createElement, useEffect } from 'react';
import Tabs from 'components/tabs/Tabs';
import { useSections } from 'hooks/useSections';
import useKeys from '@flowsn4ke/usekeys';
import { fieldTypes } from 'fieldSections/fieldTypes';
import { useAuth } from 'hooks/useAuth';

import randomFlatColors from 'random-flat-colors';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';
import { uploadCover as uploadLocationCover } from 'store/locationsSlice';
import { uploadCover as uploadEquipmentCover } from 'store/equipmentsSlice';
import { uploadCover as uploadStockCover } from 'store/stocksSlice';
import { useConfiguration } from 'hooks/useConfiguration';
import axios from 'axios';
import { apiBaseURL } from 'index';
import { assign } from '@recursive/assign';

export default function EntityForm({ element, isCreate, onSuccess, isPublic }) {
  const {
    icon,
    formComponent: FormComponent,
    getInitialValues,
    page,
    translations,
    createElement,
    updateElement,
    injectFormValues,
    customFields,
    entityFieldsSlice,
    fldx,
    closePicker,
    pickerField,
    picker,
    pickerUniq,
    entity,
    beforeCreateOrEdit,
    formValidation = {},
    formatForm,
    formDisabledIf: disableIf,
    defaultForm = {},
    listId,
    draftEnabled,
    draftField,
    formAttachment,
    facturation,
    sublistParent,
    formAttachmentIcon,
    formAttachmentLabel,
    formAttachmentMultiple,
    formAttachmentOnCreation,
    formColor,
    ...restEntity
  } = useEntity();

  const [attachments, setAttachments] = useState([]);
  const [color, setColor] = useState(null);
  useEffect(() => setColor(element?.color || randomFlatColors()), [element?.color]);

  const frmx = useForm();
  const auth = useAuth();
  const history = useHistory();
  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();

  const { t } = useTranslation();

  // TODO: Not all entities have a createSuccess or updateSuccess key...
  const dispatchCallbacks = {
    onSuccess: () => [
      !isPublic && notify.success(t(translations[isCreate ? 'createSuccess' : 'updateSuccess'])),
      onSuccess && onSuccess()
    ],
    onError: ({ code }) => notify.error(code === 401 ? "Vous n'êtes pas autorisé à effectuer cette action." : undefined)
  };

  const defaultFormValues = useMemo(
    () =>
      getInitialValues(
        auth.interface._company._configuration,
        {
          sublistParent,
          facturation,
          ...restEntity
        },
        auth.user,
        isCreate,
        auth.interface,
        auth
      ),
    [auth.interface._id, isCreate, getInitialValues]
  );

  const initialValues = useMemo(() => {
    let form = !isCreate
      ? assign(cloneDeep(defaultFormValues), pick(element, Object.keys(defaultFormValues), defaultForm))
      : { ...defaultFormValues, ...defaultForm };

    if (injectFormValues && !!element && !isCreate) {
      form = injectFormValues(form, element, auth);
    }

    return form;
  }, [element, defaultFormValues]);

  const customFormFields = {};
  const customFieldsValidation = {};

  if (customFields) {
    const elementFields =
      !isCreate &&
      (element?.fields || []).reduce((acc, curr) => {
        return {
          ...acc,
          [curr._field]: curr.value
        };
      }, {});

    customFields.forEach((section, i) => {
      section?.fields.forEach((field) => {
        customFormFields[field._id] = (elementFields && elementFields[field._id]) || field?.extra?.defaultValue || null;
        if (field?.required) {
          customFieldsValidation[field._id] = (val) => val !== null;
        }
      });
    });
  }
  return (
    <Form
      disabled={requestStatus === 'loading'}
      initialValues={{ ...initialValues, fields: customFormFields }}
      onSubmit={(data, updates) => {
        // format the custom fields for the back
        // TODO BACK: Accept an object instead of an array
        //  See https://github.com/axios/axios/issues/1548#issuecomment-548306666
        // We need to change strategies to store and send dates around
        data.fields = Object.keys(data.fields)
          .filter((f) => data.fields[f] !== null)
          .map((_field, i) => {
            const value = data.fields[_field];
            // value = value instanceof Date && formatISO(value)
            return { _field, value };
          });

        // Custom entity formatting
        if (formatForm) {
          data = formatForm(data, isCreate, attachments, auth.interface, {
            updates,
            customFields: customFields ? customFields : []
          });
        }

        const formIds = {};
        data = Object.keys(data).reduce((acc, curr) => {
          if (isObject(data[curr]) && data[curr]._id) {
            formIds[curr] = data[curr];
            return { ...acc, [curr]: data[curr]._id };
          }
          if (isArray(data[curr]) && !!data[curr]?.length && data[curr][0]?._id) {
            formIds[curr] = data[curr];
            return { ...acc, [curr]: data[curr].map((v) => v._id) };
          }

          if (isString(data[curr])) {
            return { ...acc, [curr]: data[curr]?.trim() };
          }

          return { ...acc, [curr]: data[curr] };
        }, {});

        Object.keys(data).forEach((k) => {
          if (typeof data[k] === 'undefined') {
            delete data[k];
          }
        });

        if (formColor) data.color = color;

        dispatch(isCreate ? createElement : updateElement, data, dispatchCallbacks, {
          listId,
          formIds,
          ...(isCreate && !data?._id ? {} : { id: element?._id || data?._id })
        }).then(({ data, error }) => {
          if (!error) {
            if (page && isCreate && data?.element?._id) {
              const id = data.element._id;
              const pathname = history.location.pathname.split('/');

              if (pathname?.length === 2) pathname.push(id);
              else if (pathname?.length === 3) pathname[2] = id;
              const newPathname = pathname.join('/');
              history.push(newPathname);
            }

            if (isCreate && picker) {
              if (pickerUniq) {
                fldx?.setValue(data.element);
                if (closePicker) {
                  closePicker();
                }
              } else {
                fldx?.setValue([...frmx.getOneField(pickerField), data.element]);
              }
            }
            setAttachments([]);
          }
        });
      }}
      disableIf={disableIf || (() => false)}
      schemaValidation={{ ...formValidation, fields: customFieldsValidation }}
      disableIfInvalid
      style={{
        width: '100%',
        height: `calc(100% - ${auth.interface.isPublic ? '0' : '58'}px)`,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          padding: `1.5em`,
          backgroundColor: 'white',
          flexGrow: 1,
          overflowY: 'auto',
          ...(isPublic ? { borderRadius: 8 } : {})
        }}
      >
        <FormCustomFields fieldsSlice={entityFieldsSlice}>
          <FormComponent
            element={element}
            isCreate={isCreate}
          />
        </FormCustomFields>
      </div>

      <Divider />

      <FormActions
        beforeCreateOrEdit={beforeCreateOrEdit}
        color={color}
        setColor={setColor}
        formColor={formColor}
        dispatch={dispatch}
        attachments={attachments}
        setAttachments={setAttachments}
        requestStatus={requestStatus}
        formAttachment={formAttachment}
        formAttachmentLabel={formAttachmentLabel}
        formAttachmentIcon={formAttachmentIcon}
        formAttachmentOnCreation={formAttachmentOnCreation}
        draftEnabled={draftEnabled}
        isCreate={isCreate}
        formAttachmentMultiple={formAttachmentMultiple}
        draftField={draftField}
        isPublic={isPublic}
        entity={entity}
        defaultForm={defaultForm}
        element={element}
      />
    </Form>
  );
}

function FormActions({
  requestStatus,
  formAttachment,
  formAttachmentLabel,
  formAttachmentIcon,
  formAttachmentMultiple,
  formAttachmentOnCreation,
  draftEnabled,
  draftField,
  isCreate,
  attachments,
  setAttachments,
  formColor,
  beforeCreateOrEdit,
  setColor,
  color,
  isPublic,
  dispatch,
  entity,
  defaultForm,
  element
}) {
  const { handleSubmit, setOneField, getOneField, getUpdatesList, unsetOneField, getFields } = useForm();
  const refFile = useRef(null);

  const notify = useNotifications();

  const handle_upload_attachments = ({ formData }) => {
    return axios
      .post(`${apiBaseURL}/ged/upload-documents`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(({ data }) => {
        const documents = data?.documents;
        setAttachments(
          formAttachmentMultiple ? [...attachments, ...(isArray(documents) ? documents : [documents])] : documents
        );
      })
      .catch((error) => {
        if (error.message === 'Request failed with status code 413') {
          notify.error('errorFileTooLarge');
        } else {
          notify.error();
        }
      });
  };

  const handle_change_version_document = ({ formData, document_id }) => {
    return axios
      .post(`${apiBaseURL}/ged/version/${document_id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(({ data }) => {
        const document = data?.document;
        setAttachments(
          formAttachmentMultiple ? [...attachments, ...(isArray(document) ? document : [document])] : [document]
        );
      })
      .catch((error) => {
        console.log(error);
        notify.error();
      });
  };

  const handle_upload_attachments_from_public = ({ formData }) => {
    return axios
      .post(`${apiBaseURL}/public/upload-documents`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(({ data }) => {
        const documents = data?.documents;
        setAttachments(
          formAttachmentMultiple ? [...attachments, ...(isArray(documents) ? documents : [documents])] : documents
        );
      })
      .catch((error) => {
        if (error.message === 'Request failed with status code 413') {
          notify.error('errorFileTooLarge');
        } else {
          notify.error();
        }
      });
  };

  const onChangeFile = (e) => {
    const formData = new FormData();

    if (!!e.target.files?.length) {
      // * For now we need to use another endpoint instead of the legacy uploadDocument
      // TODO: change logic when the refactoring is finished

      if (
        entity === 'leases' ||
        entity === 'guarantees' ||
        entity === 'contracts' ||
        (entity === 'tickets' && !isPublic)
      ) {
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append('file', e.target.files[i]);
        }

        // * Upload a new version of a document or a new document if the latest no have a doc (not used for tickets)
        if (!isCreate && entity !== 'tickets') {
          const document_id = element?._document?._id;

          return document_id
            ? handle_change_version_document({ formData, document_id })
            : handle_upload_attachments({ formData });
        }

        return handle_upload_attachments({ formData });
      } else if (entity === 'tickets' && isPublic) {
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append('file', e.target.files[i]);
        }
        return handle_upload_attachments_from_public({ formData });
      } else {
        Object.keys(e.target.files).forEach((f, i) => formData.append('document' + i, e.target.files[f]));
        dispatch(
          isPublic
            ? uploadDocumentPublic
            : entity === 'locations'
            ? uploadLocationCover
            : entity === 'equipments'
            ? uploadEquipmentCover
            : entity === 'stocks'
            ? uploadStockCover
            : uploadDocument,
          { formData },
          {}
        ).then(({ data, error }) => {
          if (!error) {
            setAttachments(formAttachmentMultiple ? [...attachments, ...(isArray(data) ? data : [data])] : [data]);
          }
        });
      }
    }
  };

  const { t } = useTranslation();

  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      style={{ background: 'white' }}
      paddingRight="10px"
      paddingLeft="8px"
      height="64px"
      minHeight="64px"
      className={classes.bottomRadius}
    >
      {draftEnabled && !isPublic && (
        <Tooltip
          title={t('saveAsDraft')}
          arrow
        >
          <IconButton
            onClick={() => {
              setOneField(draftField, true);
              handleSubmit();
            }}
          >
            <FAIcon
              collection="fal"
              icon="pen-to-square"
            />
          </IconButton>
        </Tooltip>
      )}

      {((formAttachment && !formAttachmentOnCreation) || (formAttachmentOnCreation && isCreate)) && (
        <>
          <Button
            size="small"
            disabled={requestStatus === 'loading' || (!isCreate && entity === 'contractors')}
            onClick={() => refFile.current.click()}
            startIcon={
              <Badge
                color={'secondary'}
                badgeContent={attachments?.length}
              >
                {formAttachmentIcon}
              </Badge>
            }
          >
            {formAttachmentLabel}
          </Button>
          <input
            type="file"
            ref={refFile}
            style={{ display: 'none' }}
            onChange={onChangeFile}
            multiple={formAttachmentMultiple}
          />
        </>
      )}

      {formColor && (
        <Box
          width="20px"
          height="20px"
          borderRadius="50%"
          bgcolor={color}
          marginRight="0px"
          boxShadow={1}
          style={{ cursor: 'pointer' }}
        >
          <input
            style={{
              opacity: 0,
              display: 'block',
              width: '100%',
              height: '100%',
              cursor: 'pointer'
            }}
            value={color}
            type={'color'}
            onChange={(e) => {
              setColor(e.target.value);
            }}
          />
        </Box>
      )}

      <Button
        disabled={requestStatus === 'loading'}
        onClick={() =>
          beforeCreateOrEdit
            ? beforeCreateOrEdit({
                getFields,
                unsetOneField,
                getUpdatesList,
                getOneField,
                setOneField,
                handleSubmit: () => handleSubmit(),
                isCreate
              })
            : handleSubmit()
        }
        color="secondary"
        variant="contained"
        className={classNames(classes.actionButton, classes.actionButtonWithIcon)}
        style={{ marginLeft: 'auto', borderRadius: '16px 16px 16px 16px' }}
        endIcon={
          requestStatus === 'loading' ? (
            <FAIcon
              icon="spinner"
              className={classNames('fa-spin', classes.actionButtonIcon)}
              collection="fas"
              size="medium"
              style={{ marginLeft: 24 }}
            />
          ) : isCreate ? (
            <AddIcon
              style={{ marginLeft: 24 }}
              className={classes.actionButtonIcon}
            />
          ) : (
            <EditIcon
              style={{ marginLeft: 24 }}
              className={classes.actionButtonIcon}
            />
          )
        }
      >
        {isCreate ? t('create') : t('edit')}
      </Button>
    </Box>
  );
}

function FormCustomFields({ fieldsSlice, children }) {
  const { hasErrors } = useForm();
  const [submittedOnce, setSubmittedOnce] = useState(false);
  useSubmitListener(() => !submittedOnce && setSubmittedOnce(true));

  const selectedDomain = useFieldObserver('category');
  const configuration = useConfiguration();

  const sections = useSections();

  const k1 = useKeys();
  const k2 = useKeys();

  const { t } = useTranslation();

  const moreThanOneTab = fieldsSlice && sections[fieldsSlice]?.length > 1;

  const tabs =
    !fieldsSlice || sections[fieldsSlice]?.length < 1
      ? [
          {
            label: '',
            content: (
              <Grid
                container
                spacing={3}
              >
                {children}
              </Grid>
            )
          }
        ]
      : sections[fieldsSlice].map((section, i) => {
          const fields =
            section.entity === 'ticket' && configuration.job_domains.length > 0
              ? section.fields.filter(
                  (field) =>
                    field.valueType !== 'autofill' &&
                    (!field.domain_id ? field : field.domain_id === selectedDomain ? field : null)
                )
              : section.fields.filter((field) => field.valueType !== 'autofill');

          return {
            label: t(section.label),
            content: (
              <Grid
                key={k1(i)}
                container
                spacing={3}
              >
                {submittedOnce && moreThanOneTab && hasErrors() && (
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      variant="body2"
                      color="error"
                    >
                      {t('pleaseCheckForSectionErrors')}
                    </Typography>
                  </Grid>
                )}
                {i === 0 && children}
                {fields.map((field, i) => {
                  const fieldComponent = fieldTypes[field.valueType].fieldComponent;
                  return (
                    <Grid
                      key={k2(i)}
                      item
                      xs={12}
                    >
                      {fieldComponent
                        ? createElement(fieldComponent, {
                            required: field.required,
                            path: `fields.${field._id}`,
                            customField: field
                          })
                        : field.valueType}
                    </Grid>
                  );
                })}
              </Grid>
            )
          };
        });

  return <Tabs tabs={tabs} />;
}

// TO IMPROVE 1st version ?
// function ContentCustomField({
//   submittedOnce,
//   hasErrors,
//   moreThanOneTab,
//   children,
//   fields,
//   section_idx
// }) {
//   const { t } = useTranslation();
//   const k = useKeys();

//   return (
//     <Grid container spacing={3}>
//       {submittedOnce && moreThanOneTab && hasErrors() && (
//         <Grid item xs={12}>
//           <Typography variant="body2" color="error">
//             {t('pleaseCheckForSectionErrors')}
//           </Typography>
//         </Grid>
//       )}
//       {section_idx === 0 && children}
//       {fields.map((field, i) => {
//         const fieldComponent = fieldTypes[field.valueType].fieldComponent;
//         return (
//           <Grid key={k(i)} item xs={12}>
//             {fieldComponent
//               ? createElement(fieldComponent, {
//                   required: field.required,
//                   path: `fields.${field._id}`,
//                   customField: field
//                 })
//               : field.valueType}
//           </Grid>
//         );
//       })}
//     </Grid>
//   );
// }
