import { Box, Chip, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Avatar from 'components/Avatar';
import useStyles from 'layouts/entities/View.styles';

import { PhoneItem, EmailItem, LastConnectItem } from 'components/views/ViewItems';
import CustomFields from '../CustomFields';
import Flag from 'react-flagkit';
import { countryLangage } from 'config/languages';
import { useTranslation } from 'react-i18next';
import FAIcon from 'components/ui/FAIcon';

export default function ManagerView({ element }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const lang = (element || element._user)?.lang || 'fr';

  return (
    <>
      <Box className={classes.l1}>
        <Box
          alignItems="center"
          display="flex"
        >
          <Avatar
            className={classes.avatarl1}
            isUser={true}
            round
            entity={{
              name: '',
              avatar: element.avatar
            }}
            shadow={false}
            size={'small'}
          />
          <Box
            flexDirection="column"
            alignItems="flex-start"
          >
            <Box className="text-base font-bold flex gap-2 items-center">
              {element.firstName} {(element?.lastName || '')?.toUpperCase()}
              <Flag
                country={countryLangage[lang]}
                className="mb-[2px] rounded-sm"
                size={20}
              />
            </Box>
            {element._role && (
              <Box marginTop={'10px'}>
                {
                  <Chip
                    className={classes.l1chip}
                    color="secondary"
                    size="small"
                    label={element._role.name}
                  />
                }
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <List className={classes.list}>
        <CustomFields element={element}>
          {!!element.phone?.length && <PhoneItem element={element} />}
          {!!element.fix?.length && (
            <PhoneItem
              element={element}
              fix
            />
          )}
          {!!element.email?.length && <EmailItem element={element} />}

          {!!element._jobs?.length && (
            <ListItem
              dense
              className={classes.listItem}
            >
              <ListItemIcon>
                {
                  <FAIcon
                    icon={'toolbox'}
                    collection={'fal'}
                    className={classes.listIcon}
                  />
                }
              </ListItemIcon>
              <ListItemText
                primary={<strong>Métier(s)</strong>}
                secondary={element._jobs.map((j) => t(j.name)).join(', ')}
              />
            </ListItem>
          )}
          {!!element?.last_connect && <LastConnectItem lastConnect={element.last_connect} />}
        </CustomFields>
      </List>
    </>
  );
}
