import {
  Box,
  List,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Fab,
  Switch
} from '@material-ui/core';
import { Empty } from 'layouts/entities/List';
import { useEffect, useState } from 'react';
import FAIcon from 'components/ui/FAIcon';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';
import { deleteAutomatisation, getAutomatisations, updateAutomatisation } from 'store/managersSlice';
import ModalAutomatisation from './ModalAutomatisation';
import useNotifications from 'hooks/useNotifications';
import { useRole } from 'hooks/useRole';

const AutomatisationManager = ({ manager, kind }) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentAutomatisation, setCurrentAutomatisation] = useState(null);

  const { t } = useTranslation();
  const { dispatch } = useAsyncDispatch();

  useEffect(() => {
    dispatch(getAutomatisations, {}, {}, { id: manager._id });
  }, [dispatch, manager._id]);

  const handleOpenModal = (automatisation = null) => {
    setCurrentAutomatisation(automatisation);
    setOpenModal(true);
  };

  const renderAutomatisations = () => (
    <>
      <List style={{ paddingTop: 0 }}>
        {manager?.automatisations?.map((automatisation, i) => (
          <AutomatisationActions
            key={i}
            automatisation={automatisation}
            i={i}
            setCurrentAutomatisation={setCurrentAutomatisation}
            setOpenModal={setOpenModal}
            manager={manager}
          />
        ))}
      </List>
      <AddButton onClick={() => handleOpenModal()} />
    </>
  );

  const renderEmptyState = () => (
    <>
      <Empty
        icon="bolt"
        collection="fas"
        translations={{
          noResultLabel: t('empty-automatisations'),
          noResultText: t('empty-text-automatisation')
        }}
      />
      <AddButton onClick={() => handleOpenModal()} />
    </>
  );

  return (
    <Box style={{ backgroundColor: 'white', height: '100%' }}>
      <ModalAutomatisation
        open={openModal}
        onClose={() => setOpenModal(false)}
        manager={manager}
        kind={kind}
        automatisation={currentAutomatisation}
      />
      {manager?.automatisations?.length > 0 ? renderAutomatisations() : renderEmptyState()}
    </Box>
  );
};

export default AutomatisationManager;

const AutomatisationActions = ({ automatisation, i, setCurrentAutomatisation, setOpenModal, manager }) => {
  const { dispatch } = useAsyncDispatch();
  const { t } = useTranslation();
  const notify = useNotifications();
  const k = useKeys();
  const { permission } = useRole();

  const handleEdit = () => {
    setCurrentAutomatisation(automatisation);
    setOpenModal(true);
  };

  const handleToggle = () => {
    dispatch(
      updateAutomatisation,
      {
        ...automatisation,
        isActive: !automatisation.isActive
      },
      {},
      {
        id: manager._id,
        automatisationId: automatisation._id
      }
    ).then(() => notify.success(t('editedAutomatisation')));
  };

  const handleDelete = () => {
    dispatch(
      deleteAutomatisation,
      {},
      {},
      {
        id: manager._id,
        automatisationId: automatisation._id
      }
    ).then(() => notify.success(t('autoIsDeleted')));
  };

  const primaryText = automatisation?.allJobs
    ? `${t('Automatisation')} (${t('allTheJobs')})`
    : automatisation._jobs?.length > 0
    ? automatisation._jobs
        .slice(0, 3)
        .map((j) => j.name)
        .join(', ') +
      (automatisation._jobs.length > 3 ? ' ...' : '') +
      ` (${automatisation._jobs.length})`
    : t('Automatisation');

  const secondaryText = automatisation?.allLocations
    ? t('allTheLocations')
    : `${automatisation?._locations?.length} ${t('locations')}`;

  return (
    <ListItem
      key={k(i)}
      className="border-b m-0 p-0 hover:bg-gray-100 cursor-pointer transition"
      onClick={handleEdit}
    >
      <ListItemAvatar>
        <FAIcon
          collection="fas"
          icon="bolt"
        />
      </ListItemAvatar>
      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
        className="mr-28"
      />
      <ListItemSecondaryAction>
        {permission('automatisations', 'update') && (
          <>
            <Tooltip title={t(automatisation.isActive ? 'autoIsActivated' : 'autoIsDeactivated')}>
              <Switch
                disabled={!permission('automatisations', 'update')}
                checked={automatisation.isActive}
                color="primary"
                onChange={handleToggle}
              />
            </Tooltip>
            <IconButton onClick={handleEdit}>
              <FAIcon
                collection="fas"
                icon="edit"
              />
            </IconButton>
          </>
        )}
        {permission('automatisations', 'delete') && (
          <IconButton onClick={handleDelete}>
            <FAIcon
              collection="fas"
              icon="trash"
            />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const AddButton = ({ onClick }) => {
  const { permission } = useRole();

  return (
    <Fab
      disabled={!permission('automatisations', 'create')}
      color="primary"
      size="small"
      style={{
        position: 'absolute',
        bottom: 16,
        right: 16
      }}
      onClick={onClick}
    >
      <FAIcon icon="plus" />
    </Fab>
  );
};
